<template>
  <CRow>
    <CCol>
      <div class="center-spinner" v-if="loading">
        <CSpinner
          color="primary"
          grow
        />
      </div>
      <div v-else>
        <CCard>
          <CCardHeader>
            <b>Profile Detail</b>
          </CCardHeader>
          <CCardBody>
            <dl class="row">
              <dt class="col-sm-3">ID</dt>
              <dd class="col-sm-9">
                <b>{{ profileData._id }}</b>
              </dd>

              <dt class="col-sm-3">User Name</dt>
              <dd class="col-sm-9">
                {{ profileData.user_name }}
              </dd>

              <dt class="col-sm-3">List ID</dt>
              <dd class="col-sm-9">
                <template>
                  {{ profileData.app_id }}
                </template>
              </dd>

              <dt class="col-sm-3">Phone Number</dt>
              <dd class="col-sm-9">
                <template>
                  {{ profileData.phone_number }}
                </template>
              </dd>
            </dl>
            <CCard v-if="profileData.extra">
              <CCardHeader>
                <b>Extra</b>
              </CCardHeader>
              <CCardBody>
                <dl class="row">
                  <template v-for="(value, key) in profileData.extra">
                    <dt class="col-sm-3" :key="key">{{ key }}</dt>
                    <dd class="col-sm-9" :key="key + '_' + value">
                      {{ value }}
                    </dd>
                  </template>
                </dl>
              </CCardBody>
            </CCard>
          </CCardBody>
        </CCard>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import AxiosBase from '../../api/AxiosBase'

export default {
  name: 'ProfileDetail',
  created() {
    this.getProfileData();
  },
  data () {
    return {
      loading: false,
      profileData: {}
    }
  },
  methods: {
    getProfileData() {
      var _this = this;
      _this.loading = true;

      AxiosBase.get("/profile/get", {
        params: {
          oid: _this.$route.params.id
        }
      })
      .catch(function(error) {
        console.log(error);
        _this.loading = false;
      })
      .then(function(res) {
        if (res?.status === 200 && res?.data) {
          _this.profileData = res?.data;
        } else {
          console.log(res);
        }
        _this.loading = false;
      });
    }
  }
}

</script>